:root {
  --font-size-display-text: 32px;
  --font-size-title: 28px;
  --font-size-sub-title: 22px;
  --font-size-primary: 16px;

  --normal-weight: 400;
  --medium-weight: 500;

  --color-red: #ff2525;
  --color-gray: #979797;
  --color-brown: #816868;
  --color-orange: #FE9920;
  --color-yellow: #F1DB4B;
  --color-green: #22ae65;
  --color-blue: #0eb7e4;
  --color-purple: #a842ec;
  --color-pink: #FE5D9F;
  --color-link: #4e71da;
  --light-color: rgba(0,0,0,.2);

  --children-spacing: 25px;
  --spacing: 10px;
}

.rnr-container *{
  font-size: var(--font-size-primary);
  font-weight: var(--normal-weight);
}

.rnr-container > .block{
  display: block;
}

.rnr-container h1,
.rnr-container h1 > * {
  font-size: var(--font-size-display-text);
  font-weight: var(--medium-weight);
}

.rnr-container h2,
.rnr-container h2 > * {
  font-size: var(--font-size-title);
  font-weight: var(--medium-weight);
}

.rnr-container h3,
.rnr-container h3 > * {
  font-size: var(--font-size-sub-title);
}

.rnr-container :-webkit-any-link {
  color: var(--color-link);
  opacity: .8;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.rnr-container :-moz-any-link {
  color: var(--color-link);
  opacity: .8;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
}

.rnr-container a,
.rnr-container :any-link {
  color: var(--color-link);
  opacity: .8;
  transition: opacity .3s;
}

.rnr-container a:focus,
.rnr-container a:hover {
  opacity: 1;
}

.rnr-container img {
  max-width: 100%;
}

.rnr-container :-webkit-any-link.title {
  color: inherit;
  text-decoration: none;
}

.rnr-container :-moz-any-link.title {
  color: inherit;
  text-decoration: none;
}

.rnr-container :any-link.title {
  color: inherit;
  text-decoration: none;
}

.rnr-container .rnr-empty-block {
  height: var(--spacing);
}

.rnr-container .rnr-bold {
  font-weight: var(--medium-weight);
}

.rnr-container .rnr-italic {
  font-style: italic;
}

.rnr-container .rnr-strikethrough {
  text-decoration: line-through;
}

.rnr-container .rnr-underline	{
  text-decoration: underline;
}

.rnr-container .rnr-underline.rnr-strikethrough {
  text-decoration: underline line-through;
}

.rnr-container .rnr-red {
  color: var(--color-red)
}

.rnr-container .rnr-gray {
  color: var(--color-gray)
}

.rnr-container .rnr-brown {
  color: var(--color-brown)
}

.rnr-container .rnr-orange {
  color: var(--color-orange)
}

.rnr-container .rnr-yellow {
  color: var(--color-yellow)
}

.rnr-container .rnr-green {
  color: var(--color-green)
}

.rnr-container .rnr-blue {
  color: var(--color-blue)
}

.rnr-container .rnr-purple {
  color: var(--color-purple)
}

.rnr-container .rnr-pink {
  color: var(--color-pink)
}

/* 
  Global styles for the components, only when are in rnr-container
*/

/* Callout */
.rnr-container .rnr-callout {
  background-color: var(--light-color);
  display: flex;
  gap: 16px;
  margin: var(--spacing) 0;
  padding: 14px;
}

/* Quote */
.rnr-container .rnr-quote {
  border-left: 4px solid var(--color-link);
  margin: var(--spacing) 0;
  padding-left: var(--children-spacing);
}

/* Table of contents */
.rnr-container .rnr-table_of_contents {
  list-style: none;
  padding: 0;
}

.rnr-container .rnr-table_of_contents > li {
  padding: 3px 0;
}

.rnr-container .rnr-table_of_contents > .rnr-heading_2 {
  margin-left: 30px;
}

.rnr-container .rnr-table_of_contents > .rnr-heading_3 {
  margin-left: 60px;
}

/* Table */
.rnr-container .rnr-table {
  border-collapse: collapse;
}

.rnr-container .rnr-table td {
  border: 1px solid var(--color-gray);
  padding: 5px;
}

.rnr-container .has-column-header tr:first-child > td {
  background-color: var(--light-color);
}

.rnr-container .has-row-header tr > td:first-child {
  background-color: var(--light-color);
}
._3bqcS {
  list-style: none;
  padding-left: 12px;
}

._2hBb1 {
  margin-right: 10px;
}

._2diKS {
  margin-left: calc(var(--children-spacing) - 12px)
}

._18UkN{
  cursor: pointer;
}